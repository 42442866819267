import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import { css } from "@emotion/core"

const ContactSuccessPage = () => (
    <Layout>
        <Container>
            <div css={css`
                margin:auto;
            `}
            >
                <div css={css`
                    background-image:url(${require('../images/illustration_contact_success.svg')});
                    background-repeat:no-repeat;
                    background-size: contain;
                    width:270px;
                    height:270px;
                    margin:auto;
                    text-align:center;
                `}>
                </div>
                <p
                    css={css`
                        text-align:center;
                        font-family: 'GTWalsheim';
                        font-size:2rem;
                        letter-spacing: -0.08em;
                        color:#1D3557;

                        @media (max-width: 960px) {
                            line-height:4rem;
                        }
                    `}
                >
                    Message envoyé avec succès !
                </p>
                <p
                    css={css`
                        text-align:center;
                        font-family: 'FreightTextBook';
                        font-size:1.2rem;
                        opacity:0.8;
                        font-weight: 600;
                        max-width:45rem;
                        margin: auto;
                    `}
                >
                    Je lirais votre message avec attention et je reviens vers vous le plus rapidement possible.
                </p>
            </div>
        </Container>
    </Layout>
)

export default ContactSuccessPage
